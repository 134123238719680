import Papa from 'papaparse';

const generateCreateTableQuery = (fileData, tableName) => {
    const results = Papa.parse(fileData, { header: true });
    const { meta } = results;

    if (meta.fields.length === 0) {
        throw new Error('No fields found in CSV data');
    }
    const columns = meta.fields.map(field => `${field} TEXT`).join(', ');

    const createTableQuery = `CREATE TABLE ${tableName} (${columns});`;

    //console.log('Generated CREATE TABLE query:', createTableQuery);

    return createTableQuery;
};

const generateInsertIntoQuery = (fileData, tableName) => {
    const results = Papa.parse(fileData, { header: true });
    const { data, meta } = results;

    if (meta.fields.length === 0) {
        throw new Error('No fields found in CSV data');
    }

    const valuesList = data.map(row => {
        const values = meta.fields.map(field => {
            // Escape single quotes by replacing ' with ''
            const escapedValue = row[field].replace(/'/g, "''");
            return `'${escapedValue}'`;
        }).join(', ');
        return `(${values})`;
    }).join(', '); // Join with a comma and space instead of newline

    const insertQuery = `INSERT INTO ${tableName} (${meta.fields.join(', ')}) VALUES ${valuesList};`;

    console.log('Generated INSERT INTO query:', insertQuery);

    return insertQuery;
};

export { generateCreateTableQuery, generateInsertIntoQuery };