import React, { useState, useEffect } from 'react';

function WMSPage() {
  const [tables, setTables] = useState([]);
  const [records, setRecords] = useState([]);
  const [selectedTable, setSelectedTable] = useState('');

  useEffect(() => {
    fetch('http://localhost:3001/api/tables')
      .then(response => response.json())
      .then(data => setTables(data))
      .catch(error => console.error("Failed to fetch tables", error));
  }, []);

  const fetchRecords = (tableName) => {
    if (tableName === selectedTable) {
      setSelectedTable('');
      setRecords([]);
    } else {
      fetch(`http://localhost:3001/api/records/${tableName}`)
        .then(response => response.json())
        .then(data => {
          setRecords(data);
          setSelectedTable(tableName);
        })
        .catch(error => console.error(`Failed to fetch records from ${tableName}`, error));
    }
  };

  const TableRecords = () => (
    <>
      {selectedTable && records.length > 0 && (
        <>
          <h2>Records in {selectedTable}</h2>
          <table>
            <thead>
              <tr>
                {Object.keys(records[0]).map((fieldName, index) => (
                  <th key={index}>{fieldName}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {records.map((record, index) => (
                <tr key={index}>
                  {Object.values(record).map((value, valueIndex) => (
                    <td key={valueIndex}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      {selectedTable && records.length === 0 && <p>No records found.</p>}
    </>
  );

  return (
    <div class='wms-body'>
      <h1>WMS Page</h1>
      <ul>
        {tables.map((tableName, index) => (
          <li key={index} onClick={() => fetchRecords(tableName)} style={{cursor: 'pointer'}}>
            {tableName}
          </li>
        ))}
      </ul>
      <TableRecords />
    </div>
  );
}

export default WMSPage;