import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CanvasComponent from '../components/canvas-components/CanvasComponent';
import useFetchTableNames from '../components/custom-hooks/DBManagePage/useFetchTableNames';

function VisualizerPage() {
  const [selectedObjects, setSelectedObjects] = useState([]);
  const { tableNames, error } = useFetchTableNames();
  const [tableName, setTableName] = useState('');
  const [inventoryData, setInventoryData] = useState([]);
  const [layoutData, setLayoutData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [layoutTableName, setLayoutTableName] = useState('');
  const [inventoryTableName, setInventoryTableName] = useState('');
  const [joinedData, setJoinedData] = useState([]);
  const [selectedObject, setSelectedObject] = useState(null);
  const [selectedDescription, setSelectedDescription] = useState('');
  const [description, setDescription] = useState('');
  const [matchingObjects, setMatchingObjects] = useState([]);
  
  const API_URL = process.env.REACT_APP_API_URL || 'https://wms-expressjs.onrender.com';
  const renderableKeys = ['location', 'item_description', 'package_size', 'weekly_movement', 'item_cost']; // Adjust this list based on your object structure

  const keyDisplayNames = {
    location: 'Location',
    item_description: 'Description',
    package_size: 'Package Size',
    weekly_movement: 'Weekly Movement',
    item_cost: 'Item Cost'
  };


  useEffect(() => {
    if (tableNames && tableNames.length > 0) {
      setTableName(tableNames[0].table_name); // Set the first table name as the default
      setLayoutTableName(tableNames[0].table_name); // Set the first table name as the layout table name
      setInventoryTableName(tableNames[0].table_name); // Set the first table name as the inventory table name
    }
  }, [tableNames]);
  

  const handleObjectSelect = (object) => {
    console.log('Object selected:', object);
    setSelectedObjects((prevObjects) => {
      let newObjects = [...prevObjects, object];

      const idCount = newObjects.reduce((acc, obj) => {
        acc[obj.id] = (acc[obj.id] || 0) + 1;
        return acc;
      }, {});

      const filteredObjects = newObjects.filter(obj => idCount[obj.id] === 1);
      console.log('Updated selectedObjects:', filteredObjects);
      return filteredObjects;
    });
  };

  const handleDescriptionChange = (event) => {
    const description = event.target.value;
    setSelectedDescription(description);
    console.log('Description Changed:', description);
    setDescription(description);

    const matchingObjects = joinedData.filter((object) => object.item_description === description);
    setMatchingObjects(matchingObjects);

    console.log('Matching Objects:', matchingObjects);
    
    // can add the matching objects to the table here
    // will need to add the ability to deselect them re-highlighting them

  };
  
  const fetchData = async (layoutTableName, inventoryTableName) => {
    setLoading(true);
    setFetchError(null);
    try {
      const layoutResponse = await axios.get(`${API_URL}/api/layout/${layoutTableName}`);
      const inventoryResponse = await axios.get(`${API_URL}/api/inventory/${inventoryTableName}`);
      setInventoryData(inventoryResponse.data);
      setLayoutData(layoutResponse.data);
      console.log('Fetched Layout Data:', layoutResponse.data);
      console.log('Fetched Inventory Data:', inventoryResponse.data);

      return {
        layoutData: layoutResponse.data,
        inventoryData: inventoryResponse.data
      };

    } catch (error) {
      setFetchError(error);
    } finally {
      setLoading(false);
    }
    return {
      layoutData: [],
      inventoryData: []
    };
  };

  const joinData = async (layoutData, inventoryData) => {
    return new Promise((resolve, reject) => {
      try {
        console.log('Joining data...');
        //console.log('Layout Data:', layoutData);
        //console.log('Inventory Data:', inventoryData);
  
        const joinedData = layoutData.map(layoutItem => {
          const inventoryItem = inventoryData.find(item => item.location === layoutItem.location);
          return {
            ...layoutItem,
            ...inventoryItem
          };
        });
  
        //console.log('Joined Data:', joinedData);
        
        resolve(joinedData);
      } catch (error) {
        reject(error);
      }
    });
  };
  
  const handleLoadWarehouse = async () => {
    console.log('Loading Warehouse with:', layoutTableName, inventoryTableName);
    // set joinedData to empty array on initial load
    setJoinedData([]);
    
    // Await fetchData to ensure it completes
    const { layoutData, inventoryData } = await fetchData(layoutTableName, inventoryTableName);
    
    // Log the fetched data to verify they are populated
    //console.log('Fetched Layout Data:', layoutData);
    //console.log('Fetched Inventory Data:', inventoryData);
    
    // Ensure layoutData and inventoryData are populated before joining
    if (layoutData.length === 0 || inventoryData.length === 0) {
      console.error('Data not properly fetched');
      return;
    }
  
    // Await joinData to ensure it completes
    const joinedData = await joinData(layoutData, inventoryData);
    setJoinedData(joinedData);
    
    // Log the joined data to verify it is correct
    console.log('Joined Data:', joinedData);

    // filter objects is set to empty array on initial load
    setSelectedObjects([]);
  };

  useEffect(() => {
    // This effect will run every time joinedData changes
    if (joinedData.length > 0) {
      // Logic to refresh or re-render the canvas component
      // For example, you might call a method on the canvas component to refresh it
      console.log('Refreshing canvas with new joined data');
      // If using a ref to access the canvas component, you could do something like:
      // canvasRef.current.refresh(joinedData);
    }
  }, [joinedData]);

  const itemDescriptions = joinedData.map((item) => item.item_description);
  const uniqueDescriptions = Array.from(new Set(itemDescriptions));

  return (
    <div>
      <title>Warehouse Visualizer</title>
      
      <div className="visualizer-wrap">
        <div className="visualizer-header">
          <label>
            Select Layout:
            <select value={layoutTableName} onChange={(e) => {
              setLayoutTableName(e.target.value);
              console.log('Selected Layout Table Name:', e.target.value);
            }}>
              {tableNames.map((name) => (
                <option key={name.table_name} value={name.table_name}>
                  {name.table_name}
                </option>
              ))}
            </select>
          </label>

          <label>
            Select Inventory:
            <select value={inventoryTableName} onChange={(e) => {
              setInventoryTableName(e.target.value);
              console.log('Selected Inventory Table Name:', e.target.value);
            }}>
              {tableNames.map((name) => (
                <option key={name.table_name} value={name.table_name}>
                  {name.table_name}
                </option>
              ))}
            </select>
          </label>

          {error && <p>Error fetching table names: {error.message}</p>}
          <button onClick={handleLoadWarehouse} disabled={loading}>
            {loading ? 'Loading...' : 'Load Warehouse'}
          </button>

            {fetchError && <p>Error fetching data: {fetchError.message}</p>}
        </div>

        <div className="visualizer-options">
            <label htmlFor='item-description-dropdown'>Item Description:</label>
            <select 
              id='item-description-dropdown'
              value={selectedDescription}
              onChange={handleDescriptionChange}
            >
            <option value="">--Select--</option>
              {uniqueDescriptions.map((description, index) => (
                <option key={index} value={description}>{description}</option>
              ))}
            </select>
          </div>


        <div className="visualizer-body">

          <CanvasComponent 
            onSelectObject={handleObjectSelect}
            joinedData={joinedData}
            onDescriptionChange={handleDescriptionChange}
            description={description} 
          />

          <div className="results-table">
            <h2 id="visualizer-table-title">Selected Object Details</h2>
            <table className="visualizer-table-body">
              <thead>
                <tr>
                  {selectedObjects.length > 0 &&
                    renderableKeys.map((key) => (
                      <th key={key} className="visualizer-table-header">{keyDisplayNames[key]}</th>
                    ))}
                </tr>
              </thead>
              <tbody className="visualizer-table-body">
                {selectedObjects.length > 0 ? (
                  selectedObjects.map((object) => (
                    <tr key={object.id}>
                      {renderableKeys.map((key) => (
                        <td key={`${object.id}-${key}`} className="visualizer-table-cell">
                          {object[key]}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={renderableKeys.length} style={{ padding: '8px', textAlign: 'center' }}>
                      Select an object for details.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>


        </div>

        <div className="visualizer-content">
          {joinedData.length > 0 && (
            <table>
              <thead>
                <tr>
                  {Object.keys(joinedData[0]).map((key) => (
                    <th key={key}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {joinedData.map((row, index) => (
                  <tr key={index}>
                    {Object.values(row).map((value, i) => (
                      <td key={i}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

      </div>
    </div>
  );
}

export default VisualizerPage;