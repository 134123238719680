import React from 'react';

function AboutPage() {
  return (
    <div className='aboutPage-body'>
        <div className='aboutPage-header'>
            <h2>About Page</h2>
                In the realms of the uncharted digital seas, where code intertwines with creativity, there existed a place of profound knowledge and mystery. Here, amidst the cascading stylesheets and the echoing queries, adventurers sought the ancient wisdom of the Pixel Elders. They spoke of a time when algorithms danced in harmony with human thought, and interfaces were as intuitive as the morning breeze.
                "Explore beyond the brackets," they whispered, "for in the depth of abstraction lies the key to simplicity." And so, the seekers of truth delved into the depths, crafting experiences that bridged worlds, connecting the ethereal with the tangible. In this land of infinite loops and recursive dreams, the quest for understanding continued, evermore into the byte-filled horizon.
        </div>

        <div className='aboutPage-content'>
            hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh
        </div>

        <div className='aboutPage-footer'>
          <section className='homePage-body-footer'>
              <h2>Contact Us</h2>
              <p>Ready to transform your warehouse operations? Contact us today to learn more about CargoFox and how we can help you achieve your goals.</p>
              <p><strong>Sales Contact:</strong> <a href="mailto:sales@cargofox.com">sales@cargofox.com</a></p>
          </section>
        </div>
    </div>
  );
}

export default AboutPage;