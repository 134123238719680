import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../styles/RegisterPage.css'; // Assuming you have a CSS file for styling
import { useNavigate } from 'react-router-dom';

const RegisterPage = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    };

  const API_URL = process.env.REACT_APP_API_URL || 'https://wms-expressjs.onrender.com';

  const onSuccess = () => {
    navigate('/dashboard');
  };

  // Set CSS variable for header height
  useEffect(() => {
    const header = document.querySelector('.header');
    const headerHeight = header.offsetHeight;
    document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
    }, []);

  const handleSubmit = async (event) => {

    event.preventDefault();

    if (!isChecked) {
        alert('You must agree to the Terms of Service to proceed.');
    }

    // Client-side validation
    if (!username || !email || !password || !confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'All fields are required.',
      });
      return;
    }

    if (password !== confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Passwords do not match.',
      });
      return;
    }

    try {
        const response = await axios.post(`${API_URL}/api/register`, {
            username,
            email,
            password
        });

        // Show success alert
        Swal.fire({
            icon: 'success',
            title: 'Registration Successful',
            text: 'Your account has been created successfully!',
        }).then(() => {
            // redirect to dashboard
            onSuccess();
        });

        // Clear input fields
        setUsername('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');

    } catch (error) {
        // Show error alert
        Swal.fire({
            icon: 'error',
            title: 'Registration Failed',
            text: 'There was an error creating your account. Please try again.',
        });
    }
};

    return (
        <div className='register-page'>
            <div className="register-container">
                <div className="register-header">
                    <h2 style={{ marginBottom: '0px' }}>Create your account</h2>
                </div>
                <form onSubmit={handleSubmit}>
                    <label className="required-label" htmlFor="username">Username</label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />

                    <label className="required-label" htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <label className="required-label" htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    <label className="required-label" htmlFor="confirmPassword">Confirm Password</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />


                    <div className="terms-of-service">
                        <input type="checkbox" id="termsOfService" required onChange={handleCheckboxChange}/>
                        <label htmlFor="termsOfService" className="required-label">I agree to the <a href="#">Terms of Service</a></label>
                    </div>
                    
                    <div className="spacer"></div>

                    <button type="submit">Register</button>
                </form>
            </div>
        </div>
    );
};

export default RegisterPage;