import { loadCSV, validateCSVData, validateSQLData } from './CSVUtils';
import { createCube, createSQLCube } from './CubeUtils'; // Import createCube function

// Function to load CSV and create cubes
export const loadAndCreateCubes = (scene) => {
  loadCSV(`${process.env.PUBLIC_URL}/warehouse_data/layout_default.csv`)
    .then(data => {
      createCubesFromCSV(scene, data);
    })
    .catch(error => {
      console.error('Error loading CSV:', error);
    });
};

// Function to create cubes from CSV data
const createCubesFromCSV = (scene, data) => {
  data.forEach(item => {
    const validatedData = validateCSVData(item);
    if (validatedData) {
      // Use LOCATION data as the object ID
      const location = validatedData.location;
      createCube(scene, validatedData, location);
    }
  });
};


// Function to create cubes from retrieved SQL data
export const createCubesFromSQL = (scene, joinedData) => {
  console.log('joinedData length:', joinedData.length);
  // Check if joinedData exists
  if (joinedData && joinedData.length > 0) {
    // Iterate over the data and create cubes
    joinedData.forEach(item => {
      //console.log('Creating cubes from SQL data:', item);
      const validatedData = validateSQLData(item);
      if (validatedData) {
        createSQLCube(scene, validatedData, validatedData.location);
      }
    });
    //console.log('Cubes created from SQL data');
  } else {
    console.error('No data found in joinedData');
  }
};