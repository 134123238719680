import * as THREE from 'three';

// Function to create and add the floor to the scene
export function addFloorToScene(scene) {
  const floorGeometry = new THREE.PlaneGeometry(100, 100); // Size of the floor
  const floorMaterial = new THREE.MeshBasicMaterial({ color: 0x808080, side: THREE.DoubleSide }); // Gray color
  const floor = new THREE.Mesh(floorGeometry, floorMaterial);
  floor.name = 'floor'; // Set the name of the floor
  floor.rotation.x = -Math.PI / 2; // Rotate the floor to be horizontal
  floor.position.y = -0.5; // Position the floor slightly below the cubes
  scene.add(floor);
}