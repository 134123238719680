import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/LoginPage.css'; // Assuming you have a CSS file for styling

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const API_URL = process.env.REACT_APP_API_URL || 'https://wms-expressjs.onrender.com';

  // Set CSS variable for header height
  useEffect(() => {
    const header = document.querySelector('.header');
    const headerHeight = header.offsetHeight;
    document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
    }, []);

  const onRegister = () => {
    navigate('/register');
  };

  const callProtectedEndpoint = async () => {
    const token = localStorage.getItem('authToken');

    if(!token) {
        console.error('No token found');
        setMessage('Login failed: No token found.');
        return;
    }

    try {
      const response = await axios.get(`${API_URL}/api/auth/protected`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Protected content:', response.data);
      setMessage('Protected content accessed successfully.');
  
      // Redirect to dashboard
      window.location.href = '/dashboard';
    } catch (error) {
      console.error('Error accessing protected content:', error);
      setMessage('Failed to access protected content.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      console.log('Posting login request');
      const response = await axios.post(`${API_URL}/api/auth/validateLogin`, {
        email,
        password,
      });
      console.log('Login successful:', response.data);
  
      // Store the token in local storage
      localStorage.setItem('authToken', response.data.token);
  
      // Redirect to dashboard
      window.location.href = '/dashboard';
    } catch (error) {
      console.error('Login failed:', error);
      setMessage('Login failed.');
    } finally {
      setLoading(false);
    }
};

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-header">
          <h2>Sign In</h2>
          <button className="register-button" onClick={onRegister}>Register</button>
        </div>
        <form onSubmit={handleSubmit}>
          <label htmlFor="email">Email address</label>
          <input
            type="text"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder=""
            required
          />

          <label htmlFor="password">Password</label>
          <input
            className="cabin-login-pw-text"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder=""
            required
          />

          <div className="remember-me-form-row">
            <label htmlFor="rememberMe">Remember Me</label>
            <input type="checkbox" name="rememberMe" />
          </div>

          <a href="#">Forgot Password?</a>
          <button type="submit" disabled={loading}>
            {loading ? 'Loading...' : 'Sign In'}
          </button>
          {loading && <div className="spinner"></div>}
          {message && <p>{message}</p>}
        </form>
      </div>
    </div>
  );
}

export default LoginPage;