import React from 'react';
import Slideshow from '../components/misc/slideshow';

function HomePage() {
    const images = [
        { src: "/images/img1.png", alt: "slideshow-img-1" },
        { src: "/images/img3.png", alt: "slideshow-img-3" },
        { src: "/images/img5.png", alt: "slideshow-img-5" },
        { src: "/images/img6.png", alt: "slideshow-img-6" },
        { src: "/images/img7.png", alt: "slideshow-img-7" },
        { src: "/images/img9.png", alt: "slideshow-img-8" }
      ];

    return (
      <div className='homePage-body'>
        <div className='homePage-body-header'></div>
        <div className="sections-container">
          <section className='homePage-body-aboutUs'>
            <h2 style={{ textAlign: 'center'}}>About Us</h2>
            <p>CargoFox revolutionizes the way warehouses operate. With our cutting-edge visualization technology, we provide real-time insights into your inventory, streamline operations, and enhance efficiency. Say goodbye to the guesswork and manual checks. Welcome to a new era of warehouse management.</p>
          </section>
          <section className='homePage-body-services'>
            <h2 style={{ textAlign: 'center'}}>Our Services</h2>
            <ul>
              <li><strong>Real-Time Inventory Tracking:</strong> Monitor your inventory with unparalleled accuracy.</li>
              <li><strong>Layout Optimization:</strong> Utilize our tools to design the perfect warehouse layout for maximum efficiency.</li>
              <li><strong>Analytics & Reporting:</strong> Gain valuable insights with our comprehensive analytics and reporting tools.</li>
            </ul>
          </section>
        </div>
        <Slideshow images={images} />
        <section className='homePage-body-footer'>
            <h2>Contact Us</h2>
            <p>Ready to transform your warehouse operations? Contact us today to learn more about CargoFox and how we can help you achieve your goals.</p>
            <p><strong>Sales Contact:</strong> <a href="mailto:sales@cargofox.com">sales@cargofox.com</a></p>
        </section>
      </div>
    );
}

export default HomePage;
