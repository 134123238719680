import * as THREE from 'three';

const setupCamera = (width, height) => {
    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    const rendererWidth = width * 0.6;
    const rendererHeight = height * 0.71;

    camera.layers.enableAll(); // Ensure the camera can see all layers
    camera.position.set(Math.PI / .2, 8, 20); // Set the camera position

    // Update camera aspect ratio and projection matrix
    camera.aspect = rendererWidth / rendererHeight;
    camera.updateProjectionMatrix();

    return camera;
};

export default setupCamera;