import React, { useState } from 'react';
import '../styles/ProfilePage.css';

const ProfilePage = () => {
  const [profile, setProfile] = useState({
    name: 'John Doe',
    bio: 'Software Developer at XYZ Company',
    profilePicture: 'https://via.placeholder.com/150'
  });

  return (
    <div className="profile-body">
        <div className="container">
            <img src={profile.profilePicture} alt="Profile" className="profilePicture" />
            <h1>{profile.name}</h1>
            <p>{profile.bio}</p>
        </div>
    </div>
  );
};

export default ProfilePage;