import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/DashboardPage.css';

const DashboardPage = () => {
  const [message, setMessage] = useState('');

  const API_URL = process.env.REACT_APP_API_URL || 'https://wms-expressjs.onrender.com';

  useEffect(() => {
    const checkToken = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        window.location.href = '/login';
        return;
      }

      try {
        const response = await axios.get(`${API_URL}/api/auth/protected`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          timeout: 5000, // Set timeout to 5 seconds
        });
        console.log('Protected content:', response.data);
        setMessage('Protected content accessed successfully.');
      } catch (error) {
        console.error('Error accessing protected content:', error);
        if (error.code === 'ECONNABORTED') {
          setMessage('Request timed out. Please try again.');
        } else {
          setMessage('Failed to access protected content. Redirecting to login.');
        }
        window.location.href = '/login';
      }
    };

    checkToken();
  }, []);

  return (
    <div className="dashboard-body">
      <h1>Dashboard</h1>
      {message && <p>{message}</p>}

      <p>
        This is a protected route. You can only access this page if you are logged in.
      </p>


    </div>
  );
};

export default DashboardPage;