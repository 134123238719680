import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const setupOrbitControls = (camera, renderer) => {
  const controls = new OrbitControls(camera, renderer.domElement);
  controls.target.set(0, 0, 0);
  controls.enableDamping = true;
  controls.dampingFactor = 0.1;
  controls.enablePan = false;
  controls.maxPolarAngle = Math.PI / 2;
  controls.zoomSpeed = 1;

  console.log('OrbitControls target:', controls.target);

  return controls;
};

export default setupOrbitControls;