import * as THREE from 'three';

// Function to create a cube and its border
export const createCube = (scene, { width, depth, height, x, y, z }, location) => {
  const geometry = new THREE.BoxGeometry(width, height, depth);
  const material = new THREE.MeshBasicMaterial({ color: 0xD2B48C }); // Light brown color
  const cube = new THREE.Mesh(geometry, material);
  cube.isCube = true; // Add custom property to identify cubes

  // Create edges for the border
  const edges = new THREE.EdgesGeometry(geometry);
  const lineMaterial = new THREE.LineBasicMaterial({ color: 0xFFFFFF }); // White color for the border
  const line = new THREE.LineSegments(edges, lineMaterial);
  line.isBorder = true; // Add custom property to identify borders
  line.name = `border`; // Add a unique name to each border

  // Set positions
  cube.position.set(x, y, z);
  cube.name = location; // Add a unique name to each cube

  line.position.set(x, y, z);

  // Add cube and border to the scene
  scene.add(cube);
  scene.add(line);
};

export const createSQLCube = (scene, { width, depth, height, x, y, z, aisle, area, level, item_number, item_description, item_cost, package_size, item_size, weekly_movement }, location) => {
  const geometry = new THREE.BoxGeometry(width, height, depth);
  const material = new THREE.MeshBasicMaterial({ color: 0xD2B48C }); // Light brown color
  const cube = new THREE.Mesh(geometry, material);
  cube.isCube = true; // Add custom property to identify cubes

  // Create edges for the border
  const edges = new THREE.EdgesGeometry(geometry);
  const lineMaterial = new THREE.LineBasicMaterial({ color: 0xFFFFFF }); // White color for the border
  const line = new THREE.LineSegments(edges, lineMaterial);
  line.isBorder = true; // Add custom property to identify borders
  line.name = `border`; // Add a unique name to each border

  // Set positions
  cube.position.set(x, y, z);
  cube.location = location; // Add a unique name to each cube
  cube.aisle = aisle;
  cube.area = area;
  cube.level = level;
  cube.item_number = item_number;
  cube.item_description = item_description;
  cube.item_cost = item_cost;
  cube.package_size = package_size;
  cube.item_size = item_size;
  cube.weekly_movement = weekly_movement;

  line.position.set(x, y, z);

  // Add cube and border to the scene
  scene.add(cube);
  scene.add(line);
};