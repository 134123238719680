import { useState, useEffect } from 'react';

const useFetchTableNames = (fetchTrigger) => {
    const [tableNames, setTableNames] = useState([]);
    const [error, setError] = useState(null);

    const API_URL = process.env.REACT_APP_API_URL || 'https://wms-expressjs.onrender.com';

    useEffect(() => {
        const fetchTableNames = async () => {
            try {
                const response = await fetch(`${API_URL}/api/fetchTableNames`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setTableNames(data);
            } catch (error) {
                console.error('Error fetching table names:', error);
                setError('Failed to fetch table names. Please try again later.');
            }
        };

        fetchTableNames();
    }, [fetchTrigger]);

    return { tableNames, error };
};

export default useFetchTableNames;