import Papa, { parse } from 'papaparse';

// Function to load and parse CSV
export const loadCSV = async (filePath) => {
  try {
    const response = await fetch(filePath);
    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }
    const text = await response.text();

    // Debug: Log the first few characters of the file content
    //console.log('File content:', text.slice(0, 300));

    // Check if the file content starts with HTML doctype
    if (text.startsWith('<!DOCTYPE html>')) {
      throw new Error('Loaded file is not a CSV, but an HTML document.');
    }

    return parseCSV(text);
  } catch (error) {
    throw new Error(`Failed to load CSV: ${error.message}`);
  }
};

// Function to parse CSV text
export const parseCSV = (text) => {
  return new Promise((resolve, reject) => {
    Papa.parse(text, {
      header: true,
      complete: (results) => resolve(results.data),
      error: (error) => reject(error),
    });
  });
};

// Function to validate CSV data
export const validateCSVData = (item) => {
  const width = parseFloat(item.WIDTH);
  const depth = parseFloat(item.DEPTH);
  const height = parseFloat(item.HEIGHT);
  const x = parseFloat(item.X);
  const y = parseFloat(item.Y);
  const z = parseFloat(item.Z);
  const aisle = parseFloat(item.AISLE);
  const area = item.AREA; // Keep as string
  const level = parseFloat(item.LEVEL);
  const location = item.LOCATION; // Keep as string

  if (isNaN(width) || isNaN(depth) || isNaN(height) || isNaN(x) || isNaN(y) || isNaN(z) || isNaN(aisle) || !area || isNaN(level) || !location) {
    console.warn('Invalid data in CSV row:', item);
    return null;
  }

  return { width, depth, height, x, y, z, aisle, area, level, location };
};

// Function to validate SQL data
export const validateSQLData = (item) => {
  const width = parseFloat(item.width);
  const depth = parseFloat(item.depth);
  const height = parseFloat(item.height);
  const x = parseFloat(item.x);
  const y = parseFloat(item.y);
  const z = parseFloat(item.z);
  const aisle = parseFloat(item.aisle);
  const area = item.area; // Keep as string
  const level = parseFloat(item.level);
  const location = item.location; // Keep as string
  const item_number = item.item_no; // Keep as string
  const item_description = item.item_description; // Keep as string
  const item_cost = parseFloat(item.item_cost);
  const package_size = item.pack; // Keep as string
  const item_size = item.size; // Keep as string
  const weekly_movement = parseFloat(item.weekly_mvmt); // Keep as number


  if (isNaN(width) || isNaN(depth) || isNaN(height) || isNaN(x) || isNaN(y) || isNaN(z) || isNaN(aisle) || !area || isNaN(level) || !location || !item_number || !item_description || isNaN(item_cost) || !package_size || !item_size || isNaN(weekly_movement)) {
    console.warn('Invalid data in SQL:', item);
    return null;
  }

  return { width, depth, height, x, y, z, aisle, area, level, location, item_number, item_description, item_cost, package_size, item_size, weekly_movement };
};