import React, { useState, useRef } from 'react';
import useFetchTableNames from '../components/custom-hooks/DBManagePage/useFetchTableNames';
import { generateCreateTableQuery, generateInsertIntoQuery } from '../components/server-components/csv-functions/csv-to-sql';
import Swal from 'sweetalert2';

const ManageConnectionsPage = () => {
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const { tableNames, error } = useFetchTableNames(fetchTrigger);
    const [tableName, setTableName] = useState('');
    const fileInputRef = useRef(null);
    const [fileData, setFileData] = useState(null);
    const [loading, setLoading] = useState(false);


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            setFileData(e.target.result);
        };
        reader.readAsText(file);
    };

    const API_URL = process.env.REACT_APP_API_URL || 'https://wms-expressjs.onrender.com';

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (tableName && fileData) {
            setLoading(true);
            const createTableQuery = generateCreateTableQuery(fileData, tableName);
            const insertIntoQuery = generateInsertIntoQuery(fileData, tableName);
    
            console.log('CREATE TABLE Query:', createTableQuery); // Log the CREATE TABLE query
            //console.log('INSERT INTO Query:', insertIntoQuery); // Log the INSERT INTO query
    
            try {
                // First, send the CREATE TABLE query
                let response = await fetch(`${API_URL}/api/createLayout`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ createTableQuery }), // Correct field name
                });
                
                if (!response.ok) {
                    throw new Error('Failed to create table');
                }
                
                if (response.ok) {
                    // Then, send the INSERT INTO query
                    response = await fetch(`${API_URL}/api/createLayout`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ insertIntoQuery }), // Correct field name
                    });

                    if (response.ok) {
                        Swal.fire({
                            title: 'Success!',
                            text: 'Table created and data inserted successfully',
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        });
                    } else {
                        Swal.fire('Error', 'Failed to insert data', 'error');
                    }
                } else {
                    Swal.fire('Error', 'Failed to create table', 'error');
                }
                // Trigger re-fetch of table names
                setFetchTrigger(prev => !prev);
            } catch (error) {
                Swal.fire('Error', 'Failed to create table or insert data', 'error');
            } finally {
                setLoading(false);
                setTableName('');
                setFileData(null);
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
            }
        }
    };


    return (
        <div className="db-body">
            <div>
                <h2>Table Names</h2>
                {error ? (
                    <div className="error">{error}</div>
                ) : (
                    <table>
                        <thead>
                            <tr>
                                <th>Table Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableNames.map((table, index) => (
                                <tr key={index}><td>{table.table_name}</td></tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            <div>
                <h2>Create New Table</h2>
                <form onSubmit={handleSubmit}>
                    <input type="text" value={tableName} onChange={(e) => setTableName(e.target.value)} placeholder="Table Name" required />
                    <input type="file" ref={fileInputRef} accept=".csv" onChange={handleFileChange} required />
                    <button type="submit" disabled={loading}>
                        {loading ? 'Loading...' : 'Submit'}
                    </button>
                </form>
                {loading && <div className="spinner">Loading...</div>}
            </div>
        </div>
    );
};

export default ManageConnectionsPage;