import * as THREE from 'three';

let isRightClickDragging = false;
let isLeftClickDragging = false;
let previousMousePosition = { x: 0, y: 0 };

export const onMouseDown = (event, scene, camera, renderer, callback) => {
  const canvas = renderer.domElement;
  const rect = canvas.getBoundingClientRect();
  const x = event.clientX - rect.left;
  const y = event.clientY - rect.top;

  if (event.button === 2) { // Right mouse button
      isRightClickDragging = true;
      previousMousePosition = { x, y };
  } else if (event.button === 0) { // Left mouse button
      isLeftClickDragging = true;
      previousMousePosition = { x, y };

      // Raycasting to detect the selected cube
      const raycaster = new THREE.Raycaster();
      const mouse = new THREE.Vector2();

      mouse.x = (x / canvas.clientWidth) * 2 - 1;
      mouse.y = -(y / canvas.clientHeight) * 2 + 1;

      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects(scene.children);

      if (intersects.length > 0) {
          // Filter out the floor object and borders
          const intersectedObjects = intersects.filter(intersect => 
              intersect.object.name !== 'floor' && intersect.object.name !== 'border'
          );
          if (intersectedObjects.length > 0) {
              const selectedCube = intersectedObjects[0].object;
              const originalColor = selectedCube.userData.originalColor || selectedCube.material.color.getHex();

              if (selectedCube.material.color.getHex() === 0x228B22) { // Forest green
                  selectedCube.material.color.setHex(originalColor);
              } else {
                  selectedCube.userData.originalColor = originalColor;
                  selectedCube.material.color.setHex(0x228B22); // Forest green
              }

              // Fetch CSV details
              const csvDetails = fetchCSVDetails(selectedCube.name);
              //console.log('Selected Object:', selectedCube);
              //console.log('CSV Details:', csvDetails);

              callback(selectedCube);
          }
      }
  }
};
  
export const onMouseUp = (event) => {
    if (event.button === 2) {
        // Stop right-click dragging
        isRightClickDragging = false;
    } else if (event.button === 0) { // Left mouse button
        isLeftClickDragging = false;
    }
};

export const onMouseMove = (event, controls, camera) => {
  if (isRightClickDragging) {
    const deltaMove = {
      x: event.clientX - previousMousePosition.x,
      y: event.clientY - previousMousePosition.y
    };

    // Pivot the camera around the center of where it is focused
    camera.rotation.y -= deltaMove.x * 0.005;
    camera.rotation.x -= deltaMove.y * 0.005;
  } else if (isLeftClickDragging) {
    const deltaMove = {
      x: event.clientX - previousMousePosition.x,
      y: event.clientY - previousMousePosition.y
    };

    // Pivot the camera around the center of where it is focused
    camera.rotation.y -= deltaMove.x * 0.005;
    camera.rotation.x -= deltaMove.y * 0.005;
  }
};

export const onMouseWheel = (event, camera) => {
  // Update the camera's y position based on the wheel delta
  camera.position.y += event.deltaY * 0.01;

  //console.log(`Camera position: x=${camera.position.x}, y=${camera.position.y}, z=${camera.position.z}`);
};

export const highlightMatchingCubes = (scene, matchingDesc) => {
  //console.log('Highlighting matching cubes:', matchingDesc);

  //console.log('highlight Scene:', scene);
  //console.log('highlight Scene children:', scene.children);

  // Check if scene has children
  if (!scene.children || scene.children.length === 0) {
    //console.error('No children found in the scene.');
    return;
  }

  // Reset all cubes to their original color
  scene.children.forEach(child => {
    if (child.isMesh && child.material && child.material.color && child.name !== 'floor') {
      child.material.color.setHex(0xD2B48C); // Tan color
      //console.log('Resetting color:', child.location);
    } else if (child.type === 'LineSegments' && child.material && child.material.color) {
      child.material.color.setHex(0xFFFFFF);
    } else if (child.name === 'floor') {
      //console.log('Skipping floor:', child);
    } else {
      //console.error('Child does not have material or color property:', child);
    }
  });

  // Highlight the matching cubes
  matchingDesc.forEach(selectedCube => {
    scene.children.forEach(child => {
      if (child.isMesh && child.location === selectedCube.location) {
        if (child.material && child.material.color) {
          child.material.color.setHex(0x228B22); // Forest green
          //console.log('Highlighting:', child.location);
        } else {
          //console.error('Child does not have material or color property:', child);
        }
      }
    });
  });
};

const fetchCSVDetails = (objectName) => {
  // Replace this with actual CSV fetching logic
  const csvDetails = {
      'cube1': 'detail1,detail2,detail3',
      'cube2': 'detailA,detailB,detailC'
  };
  return csvDetails[objectName] || 'No details available';
};