import React from 'react';
import '../styles/App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { AuthProvider, useAuth } from '../context/AuthContext';
import Navbar from '../components/Navbar';
import HomePage from '../pages/HomePage';
import AboutPage from '../pages/AboutPage';
import WMSPage from '../pages/WMSPage';
import VisualizerPage from '../pages/VisualizerPage';
import ManageConnectionsPage from '../pages/ManageConnectionsPage';
import DashboardPage from '../pages/DashboardPage';
import ProfilePage from '../pages/ProfilePage';
import LoginPage from '../pages/LoginPage';
import PrivateRoute from '../components/PrivateRoute'; // Assuming you have a PrivateRoute component
import RegisterPage from '../pages/RegisterPage';


function App() {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
}


function AppContent() {

  const { isAuthenticated } = useAuth();

  return (
        <div className="App">
          <header className="header">
            <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="cargoFoxLogo" className="logo" />
            <div className="company-name"><Link to="/" className="company-link">cargoFox</Link></div>
            {!isAuthenticated && (
              <div className="nav-login-wrapper">
                <Link to="/login" className="nav-login-button">Login</Link>
              </div>
            )}
            <Navbar />
          </header>

          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/wms" element={<PrivateRoute element={WMSPage} />} />
            <Route path="/visualizer" element={<PrivateRoute element={VisualizerPage} />} />
            <Route path="/database-manage" element={<PrivateRoute element={ManageConnectionsPage} />} />
            <Route path="/dashboard" element={<PrivateRoute element={DashboardPage} />} />
            <Route path="/profile" element={<PrivateRoute element={ProfilePage} />} />
            <Route path='/login' element={<LoginPage />} />
            <Route path='/register' element={<RegisterPage />} />
          </Routes>
        </div>
  );
}

export default App;