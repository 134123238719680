import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import setupCamera from './CameraSetup';
import setupOrbitControls from './OrbitControlsSetup';
import { loadAndCreateCubes, createCubesFromSQL } from './CubeLoader';
import { addFloorToScene } from './SceneGenerator';
import { onMouseDown, onMouseUp, onMouseMove, onMouseWheel, highlightMatchingCubes } from './SceneUtilities';

const CanvasComponent = ({ onSelectObject, joinedData, description }) => {
  const containerRef = useRef(null);
  const sceneRef = useRef(new THREE.Scene());
  const [selectedDescription, setSelectedDescription] = useState(description);

  // Initialize the scene once
  useEffect(() => {
    const scene = sceneRef.current;
    scene.name = 'layout';

    // Add initial objects to the scene here if needed
    addFloorToScene(sceneRef.current);
    
  }, []);

  // Correctly declare handleSelectedCube as a constant function
  const handleSelectedCube = (selectedCube) => {
    // Log the name of the selected cube
    //console.log(selectedCube.id);
    //console.log(selectedCube.name); // Adjusted to log the name of the object
    //console.log(selectedCube.type);
    //console.log('Selected cube:', selectedCube);
    onSelectObject(selectedCube); // Call the onSelectObject prop with the selected cube
  };

  const handleDescriptionChange = (description) => {
    setSelectedDescription(description);
    const matchingDesc = joinedData.filter(obj => obj.item_description === description);
    highlightMatchingCubes(sceneRef.current, matchingDesc);
    //console.log("handleDescriptionChange is firing")
    //console.log("Matching Desc: ", matchingDesc)
  };

  useEffect(() => {
    handleDescriptionChange(description);
    //console.log("useEffect is firing")
  }, [description]);

  useEffect(() => {
    if (!containerRef.current) return;

    const camera = setupCamera(window.innerWidth, window.innerHeight);
    const renderer = new THREE.WebGLRenderer();

    //console.log('Scene:', sceneRef.current);
    //console.log('Scene children:', sceneRef.current.children);

    //renderer.domElement.className = 'canvas-container'; // Add class to canvas

    const controls = setupOrbitControls(camera, renderer);

    const resizeRenderer = () => {
      // Determine the viewport size and set the renderer size
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const rendererWidth = Math.round(viewportWidth * 0.6);
      const rendererHeight = Math.round(viewportHeight * 0.71);
      renderer.setSize(rendererWidth, rendererHeight);
  
      // Update camera aspect ratio and projection matrix
      camera.aspect = rendererWidth / rendererHeight;
      camera.updateProjectionMatrix();
  
      // Update WebGL viewport
      renderer.setViewport(0, 0, rendererWidth, rendererHeight);
    };

  
    resizeRenderer();
    window.addEventListener('resize', resizeRenderer);

    containerRef.current.appendChild(renderer.domElement);

    // Add event listeners with console logs for debugging
    const handleMouseDown = (event) => {
      //console.log('Mouse down event:', event);
      onMouseDown(event, sceneRef.current, camera, renderer, handleSelectedCube);
    };
    const handleMouseUp = (event) => {
      //console.log('Mouse up event:', event);
      onMouseUp(event, sceneRef.current, camera, renderer);
    };
    const handleMouseMove = (event) => onMouseMove(event, sceneRef.current, camera, renderer);
    const handleMouseWheel = (event) => onMouseWheel(event, sceneRef.current, camera, renderer);

    
    renderer.domElement.addEventListener('mousedown', handleMouseDown);
    renderer.domElement.addEventListener('mouseup', handleMouseUp);
    renderer.domElement.addEventListener('mousemove', handleMouseMove);
    renderer.domElement.addEventListener('wheel', handleMouseWheel);


    // Disable right-click context menu on the canvas
    renderer.domElement.addEventListener('contextmenu', (event) => {
      event.preventDefault();
    });



    // Function to load and create cubes from joinedData
    const loadCubes = () => {
      // Clear existing cubes if necessary
      // Add logic to clear existing cubes from the scene if needed
      // Clear all children of the scene
      for (let i = sceneRef.current.children.length - 1; i >= 0; i--) {
        const child = sceneRef.current.children[i];
        sceneRef.current.remove(child);
      }
    
      if (joinedData.length === 0) {
        // Call loadAndCreateCubes if joinedData is an empty array
        loadAndCreateCubes(sceneRef.current);
      } else {
        // Create cubes from joinedData
        createCubesFromSQL(sceneRef.current, joinedData);
      }
    };

    loadCubes();

    // Add the floor back to the scene
    addFloorToScene(sceneRef.current);

    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(sceneRef.current, camera);
    };
    animate();

    return () => {
      if (containerRef.current) {
        window.removeEventListener('resize', resizeRenderer);
        containerRef.current.removeChild(renderer.domElement);
        renderer.domElement.removeEventListener('mousedown', onMouseDown);
        renderer.domElement.removeEventListener('mouseup', onMouseUp);
        renderer.domElement.removeEventListener('mousemove', (event) => onMouseMove(event, controls, camera));
        renderer.domElement.removeEventListener('wheel', (event) => onMouseWheel(event, camera));
        renderer.domElement.removeEventListener('contextmenu', (event) => {
          event.preventDefault();
        });
      }
      controls.dispose();
    };
  }, [joinedData]);

  return <div ref={containerRef} />;
};

export default CanvasComponent;