import React, { useState, useEffect } from 'react';

function Slideshow({ images }) {
  const [slideIndex, setSlideIndex] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideIndex((prevIndex) => prevIndex === images.length ? 1 : prevIndex + 1);
    }, 6000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <section className="slideshow-container">
      {images.map((image, index) => {
        // Calculate left position for each image
        let left = (index - slideIndex + 1) * 53;
        // Adjust left position if the image moves out of the viewport on the left
        if (left < -107) {
          left = 70 + (40 * (images.length - 1)); // Place the image on the right side
        }
        return (
          <img
            key={image.alt}
            src={image.src}
            className="slideshow-image"
            alt={image.alt}
            style={{ left: `${left}vw` }} // Apply calculated left position
          />
        );
      })}
    </section>
  );
}

export default Slideshow;